import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { httpOptions, httpOptionsMultipart } from 'src/app/core/utils/http-options';
import * as Models from './model.autogenerated';

export abstract class ApiAutogeneratedService {
  constructor(
    public _http: HttpClient,
    public _baseUrl: string,
  ) { }

  protected abstract _momentToString(moment: moment.Moment): string;
  protected abstract _handleRequest<T>(request: T): T;
  protected abstract _handleMultipart<T>(request: T): FormData;
  protected abstract _handleResponse<T>(response: T): T;
  protected abstract _handleError(error: any, obs: any): Observable<never>;


  public AirlineCompany_Read(idAirlineCompany: number): Observable<Models.AirlineCompany> {
    let idAirlineCompanyParam: string = encodeURIComponent('' + idAirlineCompany);
    return this._http.get<Models.AirlineCompany>(`${this._baseUrl}/api/v1/AirlineCompany/Read?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_List(filter?: string): Observable<Models.AirlineCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.AirlineCompanyListQueryResponse>(`${this._baseUrl}/api/v1/AirlineCompany/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_Create(request: Models.AirlineCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/AirlineCompany/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_Update(request: Models.AirlineCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirlineCompany/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_Delete(idAirlineCompany?: number): Observable<Models.Result> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/AirlineCompany/Delete?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_Read(idAirport?: number): Observable<Models.Airport> {
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    return this._http.get<Models.Airport>(`${this._baseUrl}/api/v1/Airport/Read?idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_List(filter?: string): Observable<Models.AirportListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.AirportListQueryResponse>(`${this._baseUrl}/api/v1/Airport/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Read(idAirportHasHotel?: number): Observable<Models.AirportHasHotel> {
    let idAirportHasHotelParam: string = idAirportHasHotel != null && idAirportHasHotel != undefined ? encodeURIComponent('' + idAirportHasHotel) : '';
    return this._http.get<Models.AirportHasHotel>(`${this._baseUrl}/api/v1/AirportHasHotel/Read?idAirportHasHotel=${idAirportHasHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_ListAirportByHotel(idHotel?: number): Observable<Models.AirportHasHotelListAirportByHotelQueryResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.AirportHasHotelListAirportByHotelQueryResponse>(`${this._baseUrl}/api/v1/AirportHasHotel/ListAirportByHotel?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Create(request: Models.AirportHasHotelCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/AirportHasHotel/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Update(request: Models.AirportHasHotelUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirportHasHotel/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Delete(request: Models.AirportHasHotelDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirportHasHotel/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_token(request: Models.AuthTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/token`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_refreshToken(request: Models.AuthRefreshTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/refreshToken`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_TokenHotacDailyRemindCommand(request: Models.AuthTokenHotacDailyRemindCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/TokenHotacDailyRemindCommand`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_TokenHotacReservationDetailRemind(request: Models.AuthTokenHotacReservationDetailRemindCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/TokenHotacReservationDetailRemind`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_TokenByIdAuthJwtToken(request: Models.AuthTokenByIdAuthJwtTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/TokenByIdAuthJwtToken`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public BedBank_AirportList(request: Models.BedBankAirportListQuery): Observable<Models.BedBankAirportListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.BedBankAirportListQueryResponse>(`${this._baseUrl}/api/v1/BedBank/AirportList`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public BedBank_HotelList(request: Models.BedBankHotelListQuery): Observable<Models.BedBankHotelListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.BedBankHotelListQueryResponse>(`${this._baseUrl}/api/v1/BedBank/HotelList`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractPlanRate_Read(idContract: number): Observable<Models.ContractPlanRateReadQueryResponse> {
    let idContractParam: string = encodeURIComponent('' + idContract);
    return this._http.get<Models.ContractPlanRateReadQueryResponse>(`${this._baseUrl}/api/v1/ContractPlanRate/Read?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Country_List(filter?: string): Observable<Models.CountryListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.CountryListQueryResponse>(`${this._baseUrl}/api/v1/Country/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Customer_List(): Observable<Models.CustomerListQueryResponse> {
    return this._http.get<Models.CustomerListQueryResponse>(`${this._baseUrl}/api/v1/Customer/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_Read(idCustomerAirlineCompany: number): Observable<Models.CustomerAirlineCompany> {
    let idCustomerAirlineCompanyParam: string = encodeURIComponent('' + idCustomerAirlineCompany);
    return this._http.get<Models.CustomerAirlineCompany>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/Read?idCustomerAirlineCompany=${idCustomerAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_List(filter?: string): Observable<Models.CustomerAirlineCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.CustomerAirlineCompanyListQueryResponse>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_ListUsers(idCustomerAirlineCompany: number): Observable<Models.CustomerAirlineCompanyListUsersQueryResponse> {
    let idCustomerAirlineCompanyParam: string = encodeURIComponent('' + idCustomerAirlineCompany);
    return this._http.get<Models.CustomerAirlineCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/ListUsers?idCustomerAirlineCompany=${idCustomerAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_Create(request: Models.CustomerAirlineCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_Update(request: Models.CustomerAirlineCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_Delete(idCustomerAirlineCompany: number): Observable<Models.Result> {
    let idCustomerAirlineCompanyParam: string = encodeURIComponent('' + idCustomerAirlineCompany);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/Delete?idCustomerAirlineCompany=${idCustomerAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompany_ListByCurrentUser(): Observable<Models.CustomerAirlineCompanyListByCurrentUserQueryResponse> {
    return this._http.get<Models.CustomerAirlineCompanyListByCurrentUserQueryResponse>(`${this._baseUrl}/api/v1/CustomerAirlineCompany/ListByCurrentUser`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompanyInAirport_List(idCustomerAirlineCompany: number): Observable<Models.CustomerAirlineCompanyInAirportListQueryResponse> {
    let idCustomerAirlineCompanyParam: string = encodeURIComponent('' + idCustomerAirlineCompany);
    return this._http.get<Models.CustomerAirlineCompanyInAirportListQueryResponse>(`${this._baseUrl}/api/v1/CustomerAirlineCompanyInAirport/List?idCustomerAirlineCompany=${idCustomerAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompanyInAirport_Read(idCustomerAirlineCompanyInAirport: number): Observable<Models.CustomerAirlineCompanyInAirport> {
    let idCustomerAirlineCompanyInAirportParam: string = encodeURIComponent('' + idCustomerAirlineCompanyInAirport);
    return this._http.get<Models.CustomerAirlineCompanyInAirport>(`${this._baseUrl}/api/v1/CustomerAirlineCompanyInAirport/Read?idCustomerAirlineCompanyInAirport=${idCustomerAirlineCompanyInAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompanyInAirport_Create(request: Models.CustomerAirlineCompanyInAirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/CustomerAirlineCompanyInAirport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompanyInAirport_Delete(idCustomerAirlineCompanyInAirport: number): Observable<Models.Result> {
    let idCustomerAirlineCompanyInAirportParam: string = encodeURIComponent('' + idCustomerAirlineCompanyInAirport);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/CustomerAirlineCompanyInAirport/Delete?idCustomerAirlineCompanyInAirport=${idCustomerAirlineCompanyInAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompanyInAirport_ListAirports(idCustomerAirlineCompany: number): Observable<Models.CustomerAirlineCompanyInAirportListQueryResponse> {
    let idCustomerAirlineCompanyParam: string = encodeURIComponent('' + idCustomerAirlineCompany);
    return this._http.get<Models.CustomerAirlineCompanyInAirportListQueryResponse>(`${this._baseUrl}/api/v1/CustomerAirlineCompanyInAirport/ListAirports?idCustomerAirlineCompany=${idCustomerAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public CustomerAirlineCompanyUploadTypeList_List(idCustomerAirlineCompany: number): Observable<Models.CustomerAirlineCompanyUploadTypeListResponse> {
    let idCustomerAirlineCompanyParam: string = encodeURIComponent('' + idCustomerAirlineCompany);
    return this._http.get<Models.CustomerAirlineCompanyUploadTypeListResponse>(`${this._baseUrl}/api/v1/CustomerAirlineCompanyUploadTypeList/List?idCustomerAirlineCompany=${idCustomerAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Enum_ListAll(): Observable<Models.EnumListQueryResponse> {
    return this._http.get<Models.EnumListQueryResponse>(`${this._baseUrl}/api/v1/Enum/ListAll`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_ReadContractByIdHotelAndDate(): Observable<Models.Contract> {
    return this._http.get<Models.Contract>(`${this._baseUrl}/api/v1/Hotac/ReadContractByIdHotelAndDate`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_Read(): Observable<Models.HotacSurveyReadResponse> {
    return this._http.get<Models.HotacSurveyReadResponse>(`${this._baseUrl}/api/v1/Hotac/Read`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_ReservationDetails(idServiceAirportToHotel: number): Observable<Models.HotacReservationDetailsResponse> {
    let idServiceAirportToHotelParam: string = encodeURIComponent('' + idServiceAirportToHotel);
    return this._http.get<Models.HotacReservationDetailsResponse>(`${this._baseUrl}/api/v1/Hotac/ReservationDetails?idServiceAirportToHotel=${idServiceAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_Save(request: Models.HotacSurveySaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Hotac/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_Create(request: Models.Hotac_Create): Observable<number> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Hotac/Create`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_UploadRoomList(request: Models.Hotac_UploadRoomList): Observable<string> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<string>(`${this._baseUrl}/api/v1/Hotac/UploadRoomList`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotac_UploadInvoice(request: Models.Hotac_UploadInvoice): Observable<string> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<string>(`${this._baseUrl}/api/v1/Hotac/UploadInvoice`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_Read(idHotel?: number): Observable<Models.HotelSummary> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.HotelSummary>(`${this._baseUrl}/api/v1/Hotel/Read?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Hotel_Details(idHotel: number, date: moment.Moment): Observable<Models.HotelDetails> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    let dateParam: string = encodeURIComponent(this._momentToString(date));
    return this._http.get<Models.HotelDetails>(`${this._baseUrl}/api/v1/Hotel/Details?idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAvailabilities_GetAvailabilityByIdAeroporto(idAirport?: number, date?: moment.Moment): Observable<Models.StopoverAvailability> {
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    let dateParam: string = date != null && date != undefined && date.isValid() ? encodeURIComponent(this._momentToString(date)) : '';
    return this._http.get<Models.StopoverAvailability>(`${this._baseUrl}/api/v1/HotelAvailabilities/GetAvailabilityByIdAeroporto?idAirport=${idAirportParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelOrder_Read(idHotelOrder: number): Observable<Models.HotelOrderReadQueryResponse> {
    let idHotelOrderParam: string = encodeURIComponent('' + idHotelOrder);
    return this._http.get<Models.HotelOrderReadQueryResponse>(`${this._baseUrl}/api/v1/HotelOrder/Read?idHotelOrder=${idHotelOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelOrder_Create(request: Models.HotelOrderCreateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelOrder/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelOrder_Update(request: Models.HotelOrderUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelOrder/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelOrderContainer_ListByIdServiceRequestAirportToHotel(idServiceRequestAirportToHotel: number): Observable<Models.HotelOrderContainerListByIdServiceRequestAirportToHotelQueryResponse> {
    let idServiceRequestAirportToHotelParam: string = encodeURIComponent('' + idServiceRequestAirportToHotel);
    return this._http.get<Models.HotelOrderContainerListByIdServiceRequestAirportToHotelQueryResponse>(`${this._baseUrl}/api/v1/HotelOrderContainer/ListByIdServiceRequestAirportToHotel?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelOrderContainer_ListByIdTicket(idTicket: number): Observable<Models.HotelOrderContainerListByIdTicketQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.HotelOrderContainerListByIdTicketQueryResponse>(`${this._baseUrl}/api/v1/HotelOrderContainer/ListByIdTicket?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelOrderContainer_Details(idHotelOrderContainer: number): Observable<Models.HotelOrderContainerDetailsQueryResponse> {
    let idHotelOrderContainerParam: string = encodeURIComponent('' + idHotelOrderContainer);
    return this._http.get<Models.HotelOrderContainerDetailsQueryResponse>(`${this._baseUrl}/api/v1/HotelOrderContainer/Details?idHotelOrderContainer=${idHotelOrderContainerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_Read(idHotel: number, idServiceRequestAirpotToHotel: number, idHotelRoomCartBooking?: number): Observable<Models.HotelRoomCartBooking> {
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    let idServiceRequestAirpotToHotelParam: string = encodeURIComponent('' + idServiceRequestAirpotToHotel);
    let idHotelRoomCartBookingParam: string = idHotelRoomCartBooking != null && idHotelRoomCartBooking != undefined ? encodeURIComponent('' + idHotelRoomCartBooking) : '';
    return this._http.get<Models.HotelRoomCartBooking>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/Read?idHotel=${idHotelParam}&idServiceRequestAirpotToHotel=${idServiceRequestAirpotToHotelParam}&idHotelRoomCartBooking=${idHotelRoomCartBookingParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_ListByIdServiceRequestAirportToHotel(idRichiestaServizioAeroportoToHotel?: number): Observable<Models.HotelRoomCartBookingListByIdServiceRequestAirportToHotelQueryResponse> {
    let idRichiestaServizioAeroportoToHotelParam: string = idRichiestaServizioAeroportoToHotel != null && idRichiestaServizioAeroportoToHotel != undefined ? encodeURIComponent('' + idRichiestaServizioAeroportoToHotel) : '';
    return this._http.get<Models.HotelRoomCartBookingListByIdServiceRequestAirportToHotelQueryResponse>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/ListByIdServiceRequestAirportToHotel?idRichiestaServizioAeroportoToHotel=${idRichiestaServizioAeroportoToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_Save(request: Models.HotelRoomCartBookingSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_BookHotels(request: Models.HotelRoomCartBookingBookHotelsCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/BookHotels`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_Delete(idCameraHotelCarrelloPrenotazione?: number): Observable<Models.Result> {
    let idCameraHotelCarrelloPrenotazioneParam: string = idCameraHotelCarrelloPrenotazione != null && idCameraHotelCarrelloPrenotazione != undefined ? encodeURIComponent('' + idCameraHotelCarrelloPrenotazione) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/Delete?idCameraHotelCarrelloPrenotazione=${idCameraHotelCarrelloPrenotazioneParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomResult_Details(idHotelRoomResult: number): Observable<Models.HotelRoomResultDetails> {
    let idHotelRoomResultParam: string = encodeURIComponent('' + idHotelRoomResult);
    return this._http.get<Models.HotelRoomResultDetails>(`${this._baseUrl}/api/v1/HotelRoomResult/Details?idHotelRoomResult=${idHotelRoomResultParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomResult_ReadByServiceRequest(idServiceRequestAirportToHotel: number, idHotel: number, date: moment.Moment): Observable<Models.HotelRoomResultDetails> {
    let idServiceRequestAirportToHotelParam: string = encodeURIComponent('' + idServiceRequestAirportToHotel);
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    let dateParam: string = encodeURIComponent(this._momentToString(date));
    return this._http.get<Models.HotelRoomResultDetails>(`${this._baseUrl}/api/v1/HotelRoomResult/ReadByServiceRequest?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}&idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomResult_Save(request: Models.HotelRoomResultSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelRoomResult/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelSurvey_List(request: Models.HotelSurveyListQuery): Observable<Models.HotelSurveyListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.HotelSurveyListQueryResponse>(`${this._baseUrl}/api/v1/HotelSurvey/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelSurvey_Read(idHotel?: number, date?: moment.Moment): Observable<Models.HotelSurveyReadResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    let dateParam: string = date != null && date != undefined && date.isValid() ? encodeURIComponent(this._momentToString(date)) : '';
    return this._http.get<Models.HotelSurveyReadResponse>(`${this._baseUrl}/api/v1/HotelSurvey/Read?idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelSurvey_Save(request: Models.HotelSurveySaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelSurvey/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public InvoiceTransportOrder_SaveByTransportOrdersCommand(request: Models.InvoiceTransportOrderSaveByTransportOrdersCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/InvoiceTransportOrder/SaveByTransportOrdersCommand`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public LanguageCulture_List(): Observable<Models.LanguageCultureListQueryResponse> {
    return this._http.get<Models.LanguageCultureListQueryResponse>(`${this._baseUrl}/api/v1/LanguageCulture/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_Read(idPassengerGroup?: number): Observable<Models.PassengerGroupResponse> {
    let idPassengerGroupParam: string = idPassengerGroup != null && idPassengerGroup != undefined ? encodeURIComponent('' + idPassengerGroup) : '';
    return this._http.get<Models.PassengerGroupResponse>(`${this._baseUrl}/api/v1/PassengerGroup/Read?idPassengerGroup=${idPassengerGroupParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_Save(request: Models.PassengerGroupSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerGroup/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_Delete(idPassengerGroup?: number): Observable<Models.Result> {
    let idPassengerGroupParam: string = idPassengerGroup != null && idPassengerGroup != undefined ? encodeURIComponent('' + idPassengerGroup) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/PassengerGroup/Delete?idPassengerGroup=${idPassengerGroupParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_ListByFilter(idTicket: number, idServiceRequestAirportToAirport?: number, idServiceRequestAirportToHotel?: number, idServiceRequestHotelToAirport?: number): Observable<Models.PassengerGroupListResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.get<Models.PassengerGroupListResponse>(`${this._baseUrl}/api/v1/PassengerGroup/ListByFilter?idTicket=${idTicketParam}&idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}&idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}&idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_ListByIdServiceAirportToHotel(idTicket?: number, idServiceAirportToHotel?: number): Observable<Models.PassengerGroupListByIdServiceAirportToHotelResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    let idServiceAirportToHotelParam: string = idServiceAirportToHotel != null && idServiceAirportToHotel != undefined ? encodeURIComponent('' + idServiceAirportToHotel) : '';
    return this._http.get<Models.PassengerGroupListByIdServiceAirportToHotelResponse>(`${this._baseUrl}/api/v1/PassengerGroup/ListByIdServiceAirportToHotel?idTicket=${idTicketParam}&idServiceAirportToHotel=${idServiceAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerParseRequest_Create(request: Models.PassengerParseRequestCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerParseRequest/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_GenerateCode(request: Models.PassengerRegistrationGenerateCodeCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerRegistration/GenerateCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_Activate(request: Models.PassengerRegistrationActivateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/PassengerRegistration/Activate`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_Token(request: Models.PassengerRegistrationTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/PassengerRegistration/Token`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_Finalize(request: Models.PassengerRegistrationFinalizeCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerRegistration/Finalize`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_ReadSummary(idTicket: number): Observable<Models.ProformaInvoiceReadSummaryQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ProformaInvoiceReadSummaryQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/ReadSummary?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_TransportOrderList(idTicket: number): Observable<Models.ProformaInvoiceTransportOrderListQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ProformaInvoiceTransportOrderListQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/TransportOrderList?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_TransportOrderDetails(idTicket: number, idTransportOrder: number): Observable<Models.ProformaInvoiceTransportOrderDetailsQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<Models.ProformaInvoiceTransportOrderDetailsQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/TransportOrderDetails?idTicket=${idTicketParam}&idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_TransportOrderItemSave(request: Models.ProformaInvoiceTransportOrderItemSaveCommand): Observable<Models.ProformaInvoiceTransportOrderDetailsQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.ProformaInvoiceTransportOrderDetailsQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/TransportOrderItemSave`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_HotelOrderContainerList(idTicket: number): Observable<Models.ProformaInvoiceHotelOrderContainerListQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ProformaInvoiceHotelOrderContainerListQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/HotelOrderContainerList?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_HotelOrderContainerDetails(idHotelOrderContainer: number): Observable<Models.ProformaInvoiceHotelOrderContainerDetailsQueryResponse> {
    let idHotelOrderContainerParam: string = encodeURIComponent('' + idHotelOrderContainer);
    return this._http.get<Models.ProformaInvoiceHotelOrderContainerDetailsQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/HotelOrderContainerDetails?idHotelOrderContainer=${idHotelOrderContainerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_HotelOrderSave(request: Models.ProformaInvoiceHotelOrderSaveCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ProformaInvoice/HotelOrderSave`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_HotelOrderReadCustomerRates(idTicket: number, idHotelOrder: number): Observable<Models.ProformaInvoiceHotelOrderReadCustomerRatesQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idHotelOrderParam: string = encodeURIComponent('' + idHotelOrder);
    return this._http.get<Models.ProformaInvoiceHotelOrderReadCustomerRatesQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/HotelOrderReadCustomerRates?idTicket=${idTicketParam}&idHotelOrder=${idHotelOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_CustomerVisibility(idTicket?: number): Observable<Models.Result> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ProformaInvoice/CustomerVisibility?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoice_TransportOrderReadCustomerRates(idTicket: number, idTransportOrder: number): Observable<Models.ProformaInvoiceTransportOrderReadCustomerRatesQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<Models.ProformaInvoiceTransportOrderReadCustomerRatesQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoice/TransportOrderReadCustomerRates?idTicket=${idTicketParam}&idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoiceCustomer_HotelOrderContainerList(idTicket: number): Observable<Models.ProformaInvoiceCustomerHotelOrderContainerListQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ProformaInvoiceCustomerHotelOrderContainerListQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoiceCustomer/HotelOrderContainerList?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoiceCustomer_HotelOrderContainerDetails(idHotelOrderContainer: number): Observable<Models.ProformaInvoiceCustomerHotelOrderContainerDetailsQueryResponse> {
    let idHotelOrderContainerParam: string = encodeURIComponent('' + idHotelOrderContainer);
    return this._http.get<Models.ProformaInvoiceCustomerHotelOrderContainerDetailsQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoiceCustomer/HotelOrderContainerDetails?idHotelOrderContainer=${idHotelOrderContainerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoiceCustomer_TransportOrderList(idTicket: number): Observable<Models.ProformaInvoiceCustomerTransportOrderListQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ProformaInvoiceCustomerTransportOrderListQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoiceCustomer/TransportOrderList?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ProformaInvoiceCustomer_TransportOrderDetails(idTicket: number, idTransportOrder: number): Observable<Models.ProformaInvoiceCustomerTransportOrderDetailsQueryResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<Models.ProformaInvoiceCustomerTransportOrderDetailsQueryResponse>(`${this._baseUrl}/api/v1/ProformaInvoiceCustomer/TransportOrderDetails?idTicket=${idTicketParam}&idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Reprotection_List(request: Models.ReprotectionListQuery): Observable<Models.ReprotectionListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.ReprotectionListQueryResponse>(`${this._baseUrl}/api/v1/Reprotection/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Reprotection_ListItem(idServiceRequestAirportToHotel: number, idHotel: number, date: moment.Moment): Observable<Models.HotelAvailabilityItem> {
    let idServiceRequestAirportToHotelParam: string = encodeURIComponent('' + idServiceRequestAirportToHotel);
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    let dateParam: string = encodeURIComponent(this._momentToString(date));
    return this._http.get<Models.HotelAvailabilityItem>(`${this._baseUrl}/api/v1/Reprotection/ListItem?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}&idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Restaurant_Read(idRestaurant?: number): Observable<Models.Restaurant> {
    let idRestaurantParam: string = idRestaurant != null && idRestaurant != undefined ? encodeURIComponent('' + idRestaurant) : '';
    return this._http.post<Models.Restaurant>(`${this._baseUrl}/api/v1/Restaurant/Read?idRestaurant=${idRestaurantParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Restaurant_List(request: Models.RestaurantListQuery): Observable<Models.RestaurantListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.RestaurantListQueryResponse>(`${this._baseUrl}/api/v1/Restaurant/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Restaurant_Create(request: Models.RestaurantCreateCommand): Observable<Models.RestaurantListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.RestaurantListQueryResponse>(`${this._baseUrl}/api/v1/Restaurant/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Restaurant_Update(request: Models.RestaurantUpdateCommand): Observable<Models.RestaurantListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.RestaurantListQueryResponse>(`${this._baseUrl}/api/v1/Restaurant/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Restaurant_Delete(idRestaurant: number): Observable<Models.Result> {
    let idRestaurantParam: string = encodeURIComponent('' + idRestaurant);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/Restaurant/Delete?idRestaurant=${idRestaurantParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceAirportToHotelHasPassengerGroup_List(idTicket?: number): Observable<Models.ServiceAirportToHotelHasPassengerGroupListQueryResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceAirportToHotelHasPassengerGroupListQueryResponse>(`${this._baseUrl}/api/v1/ServiceAirportToHotelHasPassengerGroup/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceAirportToHotelHasPassengerGroup_Save(request: Models.ServiceAirportToHotelHasPassengerGroupSaveCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ServiceAirportToHotelHasPassengerGroup/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceAirportToHotelHasPassengerGroup_Delete(idServiceAirportToHotelHasPassengerGroup?: number): Observable<Models.Result> {
    let idServiceAirportToHotelHasPassengerGroupParam: string = idServiceAirportToHotelHasPassengerGroup != null && idServiceAirportToHotelHasPassengerGroup != undefined ? encodeURIComponent('' + idServiceAirportToHotelHasPassengerGroup) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/ServiceAirportToHotelHasPassengerGroup/Delete?idServiceAirportToHotelHasPassengerGroup=${idServiceAirportToHotelHasPassengerGroupParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceAirportToHotelHasPassengerGroup_UploadRoomListInService(request: Models.ServiceAirportToHotelHasPassengerGroup_UploadRoomListInService): Observable<string> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<string>(`${this._baseUrl}/api/v1/ServiceAirportToHotelHasPassengerGroup/UploadRoomListInService`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceAirportToHotelHasPassengerGroup_RemoveRoomListInService(idServiceAirportToHotel?: number): Observable<Models.Result> {
    let idServiceAirportToHotelParam: string = idServiceAirportToHotel != null && idServiceAirportToHotel != undefined ? encodeURIComponent('' + idServiceAirportToHotel) : '';
    return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceAirportToHotelHasPassengerGroup/RemoveRoomListInService?idServiceAirportToHotel=${idServiceAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_Read(idServiceRequest?: number): Observable<Models.ServiceRequestResponse> {
    let idServiceRequestParam: string = idServiceRequest != null && idServiceRequest != undefined ? encodeURIComponent('' + idServiceRequest) : '';
    return this._http.get<Models.ServiceRequestResponse>(`${this._baseUrl}/api/v1/ServiceRequest/Read?idServiceRequest=${idServiceRequestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_CanDelete(idServiceRequest?: number): Observable<boolean> {
    let idServiceRequestParam: string = idServiceRequest != null && idServiceRequest != undefined ? encodeURIComponent('' + idServiceRequest) : '';
    return this._http.get<boolean>(`${this._baseUrl}/api/v1/ServiceRequest/CanDelete?idServiceRequest=${idServiceRequestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_List(idTicket?: number): Observable<Models.ListServiceRequestResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ListServiceRequestResponse>(`${this._baseUrl}/api/v1/ServiceRequest/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_Update(request: Models.ServiceRequestUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequest/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Read(idServiceRequestAirportToAirport?: number): Observable<Models.ServiceRequestAirportToAirportResponse> {
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    return this._http.get<Models.ServiceRequestAirportToAirportResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Read?idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_List(idTicket?: number): Observable<Models.ServiceRequestAirportToAirportListResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceRequestAirportToAirportListResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Create(request: Models.ServiceRequestAirportToAirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Update(request: Models.ServiceRequestAirportToAirportUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Delete(idServiceRequest: number, idServiceRequestAirportToAirport: number, cancellationChargeBehavior: Models.ServiceRequestCancellationDebitBehaviorEnum): Observable<Models.Result> {
    let idServiceRequestParam: string = encodeURIComponent('' + idServiceRequest);
    let idServiceRequestAirportToAirportParam: string = encodeURIComponent('' + idServiceRequestAirportToAirport);
    let cancellationChargeBehaviorParam: string = cancellationChargeBehavior != null && cancellationChargeBehavior != undefined ? encodeURIComponent('' + cancellationChargeBehavior) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Delete?idServiceRequest=${idServiceRequestParam}&idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}&cancellationChargeBehavior=${cancellationChargeBehaviorParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_TransportList(idServiceRequestAirportToAirport?: number): Observable<Models.ServiceRequestAirportToAirportTransportOrderItemListResponse> {
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    return this._http.get<Models.ServiceRequestAirportToAirportTransportOrderItemListResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/TransportList?idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Create(request: Models.ServiceRequestAirportToHotelCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Read(idServiceRequestAirportToHotel?: number): Observable<Models.ServiceRequestAirportToHotelReadResponse> {
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    return this._http.get<Models.ServiceRequestAirportToHotelReadResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Read?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_List(idTicket?: number): Observable<Models.ServiceRequestAirportToHotelListResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceRequestAirportToHotelListResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Update(request: Models.ServiceRequestAirportToHotelUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Delete(idServiceRequest: number, idServiceRequestAirportToHotel: number, cancellationChargeBehavior: Models.ServiceRequestCancellationDebitBehaviorEnum): Observable<Models.Result> {
    let idServiceRequestParam: string = encodeURIComponent('' + idServiceRequest);
    let idServiceRequestAirportToHotelParam: string = encodeURIComponent('' + idServiceRequestAirportToHotel);
    let cancellationChargeBehaviorParam: string = cancellationChargeBehavior != null && cancellationChargeBehavior != undefined ? encodeURIComponent('' + cancellationChargeBehavior) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Delete?idServiceRequest=${idServiceRequestParam}&idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}&cancellationChargeBehavior=${cancellationChargeBehaviorParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_TransportList(idServiceRequestAirportToHotel?: number): Observable<Models.ServiceRequestAirportToHotelTransportOrderItemQueryResponse> {
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    return this._http.get<Models.ServiceRequestAirportToHotelTransportOrderItemQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/TransportList?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Create(request: Models.ServiceRequestHotelToAirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_CreateServiceHotelToAirport(request: Models.ServiceRequestHotelToAirportCreateServiceHotelToAirportCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/CreateServiceHotelToAirport`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Read(idServiceRequestHotelToAirport?: number): Observable<Models.ServiceRequestHotelToAirportReadResponse> {
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.get<Models.ServiceRequestHotelToAirportReadResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Read?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_List(idTicket: number): Observable<Models.ServiceRequestHotelToAirportListResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ServiceRequestHotelToAirportListResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Update(request: Models.ServiceRequestHotelToAirportUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Delete(idServiceRequest: number, idServiceRequestHotelToAirport: number, cancellationChargeBehavior: Models.ServiceRequestCancellationDebitBehaviorEnum): Observable<Models.Result> {
    let idServiceRequestParam: string = encodeURIComponent('' + idServiceRequest);
    let idServiceRequestHotelToAirportParam: string = encodeURIComponent('' + idServiceRequestHotelToAirport);
    let cancellationChargeBehaviorParam: string = cancellationChargeBehavior != null && cancellationChargeBehavior != undefined ? encodeURIComponent('' + cancellationChargeBehavior) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Delete?idServiceRequest=${idServiceRequestParam}&idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}&cancellationChargeBehavior=${cancellationChargeBehaviorParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_TransportList(idServiceRequestHotelToAirport?: number): Observable<Models.ServiceRequestHotelToAirportTransportOrderItemQueryResponse> {
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.get<Models.ServiceRequestHotelToAirportTransportOrderItemQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/TransportList?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_ServiceList(idServiceRequestHotelToAirport: number): Observable<Models.ServiceHotelToAirportListResponse> {
    let idServiceRequestHotelToAirportParam: string = encodeURIComponent('' + idServiceRequestHotelToAirport);
    return this._http.get<Models.ServiceHotelToAirportListResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/ServiceList?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Create(request: Models.ServiceRequestSnackCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Read(idServiceRequestSnack?: number): Observable<Models.ServiceRequestSnackResponse> {
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.get<Models.ServiceRequestSnackResponse>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Read?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_List(idTicket?: number): Observable<Models.ServiceRequestSnackListQueryResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceRequestSnackListQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestSnack/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Update(request: Models.ServiceRequestSnackUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Delete(idServiceRequestSnack?: number): Observable<Models.Result> {
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Delete?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Undo(idServiceRequestSnack?: number): Observable<Models.Result> {
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Undo?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_VoucherSnackActivationList(idServiceRequestSnack: number): Observable<Models.ServiceRequestSnackVoucherSnackActivationListQueryResponse> {
    let idServiceRequestSnackParam: string = encodeURIComponent('' + idServiceRequestSnack);
    return this._http.get<Models.ServiceRequestSnackVoucherSnackActivationListQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestSnack/VoucherSnackActivationList?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceSnackBoardingPassScan_BoardingPassString(resultString?: string, idServiceRequestSnack?: number): Observable<number> {
    let resultStringParam: string = resultString != null && resultString != undefined ? encodeURIComponent('' + resultString) : '';
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceSnackBoardingPassScan/BoardingPassString?resultString=${resultStringParam}&idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Supplier_List(filter?: string): Observable<Models.SupplierListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.SupplierListQueryResponse>(`${this._baseUrl}/api/v1/Supplier/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_Read(idSupplierFoodServiceCompany: number): Observable<Models.SupplierFoodServiceCompanyReadResponse> {
    let idSupplierFoodServiceCompanyParam: string = encodeURIComponent('' + idSupplierFoodServiceCompany);
    return this._http.get<Models.SupplierFoodServiceCompanyReadResponse>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/Read?idSupplierFoodServiceCompany=${idSupplierFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_List(filter?: string): Observable<Models.SupplierFoodServiceCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.SupplierFoodServiceCompanyListQueryResponse>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_ListUsers(idSupplierFoodServiceCompany: number): Observable<Models.SupplierFoodServiceCompanyListUsersQueryResponse> {
    let idSupplierFoodServiceCompanyParam: string = encodeURIComponent('' + idSupplierFoodServiceCompany);
    return this._http.get<Models.SupplierFoodServiceCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/ListUsers?idSupplierFoodServiceCompany=${idSupplierFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_Create(request: Models.SupplierFoodServiceCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_Update(request: Models.SupplierFoodServiceCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_Delete(idSupplierFoodServiceCompany: number): Observable<Models.Result> {
    let idSupplierFoodServiceCompanyParam: string = encodeURIComponent('' + idSupplierFoodServiceCompany);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/Delete?idSupplierFoodServiceCompany=${idSupplierFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompany_ReadByIdUser(idUser: number): Observable<Models.SupplierFoodServiceCompany> {
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.get<Models.SupplierFoodServiceCompany>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompany/ReadByIdUser?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompanyUser_ListVoucherSnackByBoardingPass(boardingPass: string): Observable<Models.SupplierFoodServiceCompanyUserListVoucherSnackByBoardingPassResponse> {
    let boardingPassParam: string = encodeURIComponent('' + boardingPass);
    return this._http.get<Models.SupplierFoodServiceCompanyUserListVoucherSnackByBoardingPassResponse>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompanyUser/ListVoucherSnackByBoardingPass?boardingPass=${boardingPassParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompanyUser_ListBoardingPassScanned(): Observable<Models.SupplierFoodServiceCompanyVoucherSnackBoardingPassScannedListResponse> {
    return this._http.get<Models.SupplierFoodServiceCompanyVoucherSnackBoardingPassScannedListResponse>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompanyUser/ListBoardingPassScanned`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompanyUser_EmitVoucherSnack(request: Models.SupplierFoodServiceCompanyUserEmitVoucherSnackCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompanyUser/EmitVoucherSnack`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierFoodServiceCompanyVoucherSnack_DownloadOrderForm(idTicket: number, idSupplierFoodServiceCompany: number): Observable<string> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idSupplierFoodServiceCompanyParam: string = encodeURIComponent('' + idSupplierFoodServiceCompany);
    return this._http.post<string>(`${this._baseUrl}/api/v1/SupplierFoodServiceCompanyVoucherSnack/DownloadOrderForm?idTicket=${idTicketParam}&idSupplierFoodServiceCompany=${idSupplierFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_List(filter?: string): Observable<Models.SupplierTransportCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.SupplierTransportCompanyListQueryResponse>(`${this._baseUrl}/api/v1/SupplierTransportCompany/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_Read(idSupplierTransportCompany: number): Observable<Models.SupplierTransportCompanyReadResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.SupplierTransportCompanyReadResponse>(`${this._baseUrl}/api/v1/SupplierTransportCompany/Read?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_ReadByIdUser(idUser?: number): Observable<Models.SupplierTransportCompany> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.get<Models.SupplierTransportCompany>(`${this._baseUrl}/api/v1/SupplierTransportCompany/ReadByIdUser?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_ListUsers(idSupplierTransportCompany?: number): Observable<Models.SupplierTransportCompanyListUsersQueryResponse> {
    let idSupplierTransportCompanyParam: string = idSupplierTransportCompany != null && idSupplierTransportCompany != undefined ? encodeURIComponent('' + idSupplierTransportCompany) : '';
    return this._http.get<Models.SupplierTransportCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/SupplierTransportCompany/ListUsers?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_Create(request: Models.SupplierTransportCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/SupplierTransportCompany/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_Update(request: Models.SupplierTransportCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/SupplierTransportCompany/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompany_Delete(idSupplierTransportCompany?: number): Observable<Models.Result> {
    let idSupplierTransportCompanyParam: string = idSupplierTransportCompany != null && idSupplierTransportCompany != undefined ? encodeURIComponent('' + idSupplierTransportCompany) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/SupplierTransportCompany/Delete?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompanyContact_List(idSupplierTransportCompany?: number): Observable<Models.SupplierTransportCompanyContactListQueryResponse> {
    let idSupplierTransportCompanyParam: string = idSupplierTransportCompany != null && idSupplierTransportCompany != undefined ? encodeURIComponent('' + idSupplierTransportCompany) : '';
    return this._http.get<Models.SupplierTransportCompanyContactListQueryResponse>(`${this._baseUrl}/api/v1/SupplierTransportCompanyContact/List?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompanyContact_Read(idSupplierTransportCompanyContact: number): Observable<Models.SupplierTransportCompanyContact> {
    let idSupplierTransportCompanyContactParam: string = encodeURIComponent('' + idSupplierTransportCompanyContact);
    return this._http.get<Models.SupplierTransportCompanyContact>(`${this._baseUrl}/api/v1/SupplierTransportCompanyContact/Read?idSupplierTransportCompanyContact=${idSupplierTransportCompanyContactParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompanyContact_Create(request: Models.SupplierTransportCompanyContactCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/SupplierTransportCompanyContact/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompanyContact_Update(request: Models.SupplierTransportCompanyContactUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/SupplierTransportCompanyContact/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public SupplierTransportCompanyContact_Delete(idSupplierTransportCompanyContact?: number): Observable<Models.Result> {
    let idSupplierTransportCompanyContactParam: string = idSupplierTransportCompanyContact != null && idSupplierTransportCompanyContact != undefined ? encodeURIComponent('' + idSupplierTransportCompanyContact) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/SupplierTransportCompanyContact/Delete?idSupplierTransportCompanyContact=${idSupplierTransportCompanyContactParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Test_TestError(): Observable<number> {
    return this._http.post<number>(`${this._baseUrl}/api/v1/Test/TestError`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Test_ReadDate(): Observable<moment.Moment> {
    return this._http.get<moment.Moment>(`${this._baseUrl}/api/v1/Test/ReadDate`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Read(idTicket?: number): Observable<Models.Ticket> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.Ticket>(`${this._baseUrl}/api/v1/Ticket/Read?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_ReadHotelClosedAtByIdServiceAirportToHotel(idServiceAirportToHotel?: number): Observable<Models.TicketReadHotelClosedAtByIdServiceAirportToHotelQueryResponse> {
    let idServiceAirportToHotelParam: string = idServiceAirportToHotel != null && idServiceAirportToHotel != undefined ? encodeURIComponent('' + idServiceAirportToHotel) : '';
    return this._http.get<Models.TicketReadHotelClosedAtByIdServiceAirportToHotelQueryResponse>(`${this._baseUrl}/api/v1/Ticket/ReadHotelClosedAtByIdServiceAirportToHotel?idServiceAirportToHotel=${idServiceAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Details(idTicket?: number): Observable<Models.Ticket> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.Ticket>(`${this._baseUrl}/api/v1/Ticket/Details?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Create(request: Models.TicketCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Ticket/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Update(request: Models.TicketUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_List(request: Models.TicketListQuery): Observable<Models.TicketListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.TicketListQueryResponse>(`${this._baseUrl}/api/v1/Ticket/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_CloseTransport(idTicket: number): Observable<Models.Result> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/CloseTransport?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_CloseClient(idTicket: number): Observable<Models.Result> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/CloseClient?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_CloseHotel(idTicket: number): Observable<Models.Result> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/CloseHotel?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_UpdateNote(request: Models.TicketUpdateNoteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/UpdateNote`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Delete(idTicket: number): Observable<Models.Result> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/Delete?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_PassengersNumberOnOutgoingServices(idTicket?: number): Observable<number> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<number>(`${this._baseUrl}/api/v1/Ticket/PassengersNumberOnOutgoingServices?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TicketFile_ListByIdTicket(idTicket?: number): Observable<Models.TicketFileListByIdTicketQueryResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.TicketFileListByIdTicketQueryResponse>(`${this._baseUrl}/api/v1/TicketFile/ListByIdTicket?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TicketFile_Create(request: Models.TicketFile_Create): Observable<number> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/TicketFile/Create`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TicketFile_Delete(idTicketFile?: number): Observable<Models.Result> {
    let idTicketFileParam: string = idTicketFile != null && idTicketFile != undefined ? encodeURIComponent('' + idTicketFile) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/TicketFile/Delete?idTicketFile=${idTicketFileParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListRequested(idSupplierTransportCompany: number): Observable<Models.TransportOrderListToInvoiceQueryResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.TransportOrderListToInvoiceQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListRequested?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListInProgress(idSupplierTransportCompany: number): Observable<Models.TransportOrderListInProgressQueryResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.TransportOrderListInProgressQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListInProgress?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListToInovice(idSupplierTransportCompany: number): Observable<Models.TransportOrderListToInvoiceQueryResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.TransportOrderListToInvoiceQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListToInovice?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListCanceled(idSupplierTransportCompany: number): Observable<Models.TransportOrderListCanceledQueryResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.TransportOrderListCanceledQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListCanceled?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListDeleted(idSupplierTransportCompany: number): Observable<Models.TransportOrderListDeletedQueryResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.TransportOrderListDeletedQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListDeleted?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListClosed(idSupplierTransportCompany: number): Observable<Models.TransportOrderListClosedQueryResponse> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    return this._http.get<Models.TransportOrderListClosedQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListClosed?idSupplierTransportCompany=${idSupplierTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_Read(idSupplierTransportCompany: number, idTransportOrder: number): Observable<Models.TransportOrderListItem> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<Models.TransportOrderListItem>(`${this._baseUrl}/api/v1/TransportOrder/Read?idSupplierTransportCompany=${idSupplierTransportCompanyParam}&idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ReadListItem(idSupplierTransportCompany: number, idTransportOrder: number): Observable<Models.TransportOrderListItem> {
    let idSupplierTransportCompanyParam: string = encodeURIComponent('' + idSupplierTransportCompany);
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<Models.TransportOrderListItem>(`${this._baseUrl}/api/v1/TransportOrder/ReadListItem?idSupplierTransportCompany=${idSupplierTransportCompanyParam}&idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_Update(request: Models.TransportOrderUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/TransportOrder/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ReadTotalAmount(idTransportOrder: number): Observable<number> {
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<number>(`${this._baseUrl}/api/v1/TransportOrder/ReadTotalAmount?idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Read(idTransportOrderItem: number): Observable<Models.TransportOrderItem> {
    let idTransportOrderItemParam: string = encodeURIComponent('' + idTransportOrderItem);
    return this._http.get<Models.TransportOrderItem>(`${this._baseUrl}/api/v1/TransportOrderItem/Read?idTransportOrderItem=${idTransportOrderItemParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_ListByIdTransportOrder(idTransportOrder: number): Observable<Models.TransportOrderItemListByIdTransportOrderQueryResponse> {
    let idTransportOrderParam: string = encodeURIComponent('' + idTransportOrder);
    return this._http.get<Models.TransportOrderItemListByIdTransportOrderQueryResponse>(`${this._baseUrl}/api/v1/TransportOrderItem/ListByIdTransportOrder?idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Create(request: Models.TransportOrderItemCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/TransportOrderItem/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Update(request: Models.TransportOrderItemUpdateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<number>(`${this._baseUrl}/api/v1/TransportOrderItem/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Delete(idTransportOrderItem?: number): Observable<Models.Result> {
    let idTransportOrderItemParam: string = idTransportOrderItem != null && idTransportOrderItem != undefined ? encodeURIComponent('' + idTransportOrderItem) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/TransportOrderItem/Delete?idTransportOrderItem=${idTransportOrderItemParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_AmountSave(request: Models.TransportOrderItemAmountSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<number>(`${this._baseUrl}/api/v1/TransportOrderItem/AmountSave`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Create(request: Models.UserCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/User/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Delete(idUser?: number): Observable<Models.Result> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/Delete?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Update(request: Models.UserUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_List(request: Models.UserListQuery): Observable<Models.UserListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.UserListQueryResponse>(`${this._baseUrl}/api/v1/User/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Read(idUser?: number): Observable<Models.UserReadQueryResponse> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.post<Models.UserReadQueryResponse>(`${this._baseUrl}/api/v1/User/Read?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ReadByUniqueCode(uniqueCode: string, resetPasswordCode: string): Observable<Models.UserReadByUniqueCodeQueryResponse> {
    let uniqueCodeParam: string = encodeURIComponent('' + uniqueCode);
    let resetPasswordCodeParam: string = encodeURIComponent('' + resetPasswordCode);
    return this._http.post<Models.UserReadByUniqueCodeQueryResponse>(`${this._baseUrl}/api/v1/User/ReadByUniqueCode?uniqueCode=${uniqueCodeParam}&resetPasswordCode=${resetPasswordCodeParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ReadByUsername(username: string): Observable<Models.UserReadByUsernameQueryResponse> {
    let usernameParam: string = encodeURIComponent('' + username);
    return this._http.post<Models.UserReadByUsernameQueryResponse>(`${this._baseUrl}/api/v1/User/ReadByUsername?username=${usernameParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_DeleteUserInFoodServiceCompany(idUser: number, idSupplierFoodServiceCompany: number): Observable<Models.Result> {
    let idUserParam: string = encodeURIComponent('' + idUser);
    let idSupplierFoodServiceCompanyParam: string = encodeURIComponent('' + idSupplierFoodServiceCompany);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/User/DeleteUserInFoodServiceCompany?idUser=${idUserParam}&idSupplierFoodServiceCompany=${idSupplierFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_UpdateLanguageCulture(idLanguageCulture?: number): Observable<Models.Result> {
    let idLanguageCultureParam: string = idLanguageCulture != null && idLanguageCulture != undefined ? encodeURIComponent('' + idLanguageCulture) : '';
    return this._http.get<Models.Result>(`${this._baseUrl}/api/v1/User/UpdateLanguageCulture?idLanguageCulture=${idLanguageCultureParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ListUsersInFoodServiceCompany(idSupplierFoodServiceCompany: number): Observable<Models.SupplierFoodServiceCompanyListUsersQueryResponse> {
    let idSupplierFoodServiceCompanyParam: string = encodeURIComponent('' + idSupplierFoodServiceCompany);
    return this._http.get<Models.SupplierFoodServiceCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/User/ListUsersInFoodServiceCompany?idSupplierFoodServiceCompany=${idSupplierFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ListByEntity(entityInstanceId: number, idEntity: Models.EntityEnum): Observable<Models.UserListByEntityQueryResponse> {
    let entityInstanceIdParam: string = encodeURIComponent('' + entityInstanceId);
    let idEntityParam: string = encodeURIComponent('' + idEntity);
    return this._http.get<Models.UserListByEntityQueryResponse>(`${this._baseUrl}/api/v1/User/ListByEntity?entityInstanceId=${entityInstanceIdParam}&idEntity=${idEntityParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ReadUserInfo(): Observable<Models.UserReadUserInfoQueryResponse> {
    return this._http.get<Models.UserReadUserInfoQueryResponse>(`${this._baseUrl}/api/v1/User/ReadUserInfo`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_GenerateResetPasswordCode(request: Models.UserGenerateResetPasswordCodeCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/GenerateResetPasswordCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_VerifyResetPasswordCode(request: Models.UserVerifyResetPasswordCodeCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/VerifyResetPasswordCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ResetPassword(request: Models.UserResetPasswordCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/ResetPassword`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public UserInEntity_Create(idEntity: Models.EntityEnum, entityInstanceId: number, idUser: number): Observable<Models.Result> {
    let idEntityParam: string = encodeURIComponent('' + idEntity);
    let entityInstanceIdParam: string = encodeURIComponent('' + entityInstanceId);
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/UserInEntity/Create?idEntity=${idEntityParam}&entityInstanceId=${entityInstanceIdParam}&idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public UserInEntity_CreateByUsername(idEntity: Models.EntityEnum, entityInstanceId: number, email: string): Observable<string> {
    let idEntityParam: string = encodeURIComponent('' + idEntity);
    let entityInstanceIdParam: string = encodeURIComponent('' + entityInstanceId);
    let emailParam: string = encodeURIComponent('' + email);
    return this._http.post<string>(`${this._baseUrl}/api/v1/UserInEntity/CreateByUsername?idEntity=${idEntityParam}&entityInstanceId=${entityInstanceIdParam}&email=${emailParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public UserInEntity_Delete(idEntity: Models.EntityEnum, entityInstanceId: number, idUser: number): Observable<Models.Result> {
    let idEntityParam: string = encodeURIComponent('' + idEntity);
    let entityInstanceIdParam: string = encodeURIComponent('' + entityInstanceId);
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/UserInEntity/Delete?idEntity=${idEntityParam}&entityInstanceId=${entityInstanceIdParam}&idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_ActivatedList(referenceDate: moment.Moment): Observable<Models.ActivatedVoucherSnackReadQueryResponse> {
    let referenceDateParam: string = encodeURIComponent(this._momentToString(referenceDate));
    return this._http.get<Models.ActivatedVoucherSnackReadQueryResponse>(`${this._baseUrl}/api/v1/VoucherSnack/ActivatedList?referenceDate=${referenceDateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_List(idCustomer?: number): Observable<Models.VoucherSnackListByIdCustomerResponse> {
    let idCustomerParam: string = idCustomer != null && idCustomer != undefined ? encodeURIComponent('' + idCustomer) : '';
    return this._http.get<Models.VoucherSnackListByIdCustomerResponse>(`${this._baseUrl}/api/v1/VoucherSnack/List?idCustomer=${idCustomerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_SnackTypeListByIdCustomer(idCustomer: number): Observable<Models.SnackTypeListByIdCustomerResponse> {
    let idCustomerParam: string = encodeURIComponent('' + idCustomer);
    return this._http.get<Models.SnackTypeListByIdCustomerResponse>(`${this._baseUrl}/api/v1/VoucherSnack/SnackTypeListByIdCustomer?idCustomer=${idCustomerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_Create(request: Models.VoucherSnackCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/VoucherSnack/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_Read(idVoucherSnackRequestEmission?: number): Observable<Models.VoucherSnackResponse> {
    let idVoucherSnackRequestEmissionParam: string = idVoucherSnackRequestEmission != null && idVoucherSnackRequestEmission != undefined ? encodeURIComponent('' + idVoucherSnackRequestEmission) : '';
    return this._http.get<Models.VoucherSnackResponse>(`${this._baseUrl}/api/v1/VoucherSnack/Read?idVoucherSnackRequestEmission=${idVoucherSnackRequestEmissionParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_ReadByIdSnackType(idSnackType?: number): Observable<Models.SnackType> {
    let idSnackTypeParam: string = idSnackType != null && idSnackType != undefined ? encodeURIComponent('' + idSnackType) : '';
    return this._http.get<Models.SnackType>(`${this._baseUrl}/api/v1/VoucherSnack/ReadByIdSnackType?idSnackType=${idSnackTypeParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_Activate(request: Models.VoucherSnackActivateCommand): Observable<Models.VoucherSnackActivateCommandResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.VoucherSnackActivateCommandResponse>(`${this._baseUrl}/api/v1/VoucherSnack/Activate`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_ActivateWithBoardingPass(request: Models.VoucherSnackActivateWithBoardingPassCommand): Observable<Models.VoucherSnackActivateWithBoardingPassCommandResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.VoucherSnackActivateWithBoardingPassCommandResponse>(`${this._baseUrl}/api/v1/VoucherSnack/ActivateWithBoardingPass`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

}